import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'
import '../assets/main.css'
import heroStyles from "../components/hero.module.css";
import Compare from './compare-components/dropdown-compare.js'
import Breadcrumbs from './Breadcrumbs'
import Loan from './compare-components/loan-svg.js'

class Template extends React.Component {
  
  
  render() {
    //const { children } = this.props
    const {title} = this.props
    return (
      <div style={{ background: '#fff' }}> {/* Taking this out ruins navbar color for some reason */}
      <Navigation />

      {this.props.image ? 
      <div className="bg-white w-full">

    </div> : <></>}
      <Breadcrumbs url={this.props.location.pathname} title={title}/>
      <Container>
      <div className="flex grid-cols-5" style={{flexWrap: 'wrap',}}>
      
                    <div className="lg:w-4/5 w-full">

                        {this.props.mainContent}
                        
                     </div>
                    <div className="lg:w-1/5 w-full z-0" style={{zIndex: "1"}}>
                     
                           {this.props.sideContent}
                       
                    </div> 
      </div>
      </Container>

    </div>
    )
  }
}

export default Template