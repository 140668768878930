import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/review-post-layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Tags from "../components/body-tags";
import heroStyles from "../components/hero.module.css";
import '../assets/main.css'
import SideContent from '../components/side-components/lender-side.js'

class ReviewPostTemplate extends React.Component {

 
  render() {

    const siteTitle = get(this.props, "data.site.siteMetadata.title");
    const articles = get(this, 'props.data.allContentfulLender.edges');
    const post = get(this.props, "data.contentfulLender");


    const mainContent = (
  <div>
        <div className="flex" style={{flexWrap: 'wrap',}}>
                <div class="lg:w-1/4 w-full ">
                                
                        <div className="js-toc sticky top-0 pt-12"></div>
                   
                </div>
                    <div class="lg:w-3/4 w-full">
                    <img class="mx-auto w-auto max-w-full" src={post.image.file.url} alt="Room Image" />
                    <h1 className="pt-4">{post.title}</h1>
                        <div className="js-toc-content px-2">
                            <Tags body={post.body.json}/>
                        </div>
                    </div>
                </div>
            </div>
 
 );
    console.log('post', post);
    return (
      <Layout title={post.title} location={this.props.location} mainContent={mainContent} image = {post.image.file.url} sideContent={<SideContent articles={articles} />}>
        
          <Helmet title={`${post.name} | ${siteTitle}`} />
            
      
        
      </Layout>
    );
  }
}

export default ReviewPostTemplate;

export const pageQuery = graphql`
  query LenderBySlug($slug: String!) {

  allContentfulLender(limit: 8, sort: { fields: [title], order: DESC }) {
      edges {
        node {
          title
          slug
          description {
            description
          }
          image {
            file {
                url
            }
         }
        }
      }
    }


    contentfulLender(slug: { eq: $slug }) {
      title
      slug
      publishDate
      body {
        json
      }
      image {
            file {
                url
            }
         }
    }
  }
`;
